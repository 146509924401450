import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { File } from '@model/generated/index';
import { Link } from 'gatsby';

type Props = {
  className: string;
  images: {
    id: string;
    image: File;
    subtitle: string;
    title: string;
    slug: string;
  }[];
};

export const Gallery: React.FC<Props> = (props) => {
  const { className, images } = props;
  return (
    <div
      className={`${className} grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8 md:gap-16`}
    >
      {images.map(
        (item) =>
          item.image && (
            <Link
              key={item.id}
              to={`/digital_diary/${item.slug}`}
              className="bg-white border-4 border-black no-underline"
            >
              <div>
                <GatsbyImage
                  className="w-full h-200"
                  objectFit="cover"
                  image={item.image.childImageSharp.gatsbyImageData}
                  alt={item.title}
                />
              </div>
              <div className="p-8">
                <h2 className="text-16 text-black border-none p-0">
                  {item.title}
                </h2>
                <h3 className="text-12 text-black p-0">{item.subtitle}</h3>
              </div>
            </Link>
          ),
      )}
    </div>
  );
};
