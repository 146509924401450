import React from 'react';
import { Layout } from '@components/global/Layout';
import { graphql, Link, PageProps } from 'gatsby';
import { MarkdownRemarkConnection } from '@model/generated';
import { Gallery } from '@components/global/Gallery';
import { RouteEnum } from '@model/route.enum';
import { Meta } from '@components/global/Meta';

type DataProps = {
  allDigitalDiary: MarkdownRemarkConnection;
};

type Props = PageProps<DataProps>;

export const query = graphql`
  query {
    allDigitalDiary: allMarkdownRemark(
      sort: { fields: frontmatter___published_date, order: DESC }
      filter: {
        fileAbsolutePath: { regex: "markdown/digital_diary/.*.md$/" }
        frontmatter: { is_displayed: { eq: true } }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            is_displayed
            title
            slug
            published_date
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`;

const Page: React.FC<Props> = (props) => {
  const { data } = props;
  return (
    <Layout title="Digital Diary" navigationItems={[]}>
      <div className="flex">
        <div className="flex-auto">
          <div className="flex-1 p-32 mb-16">
            <p>
              <b>Digital Diary</b> - Ein Museumstagebuch im World Wide Web:
              Heute beginnt eine Serie, in der in regelmäßiger Folge – jeweils
              am Samstag - Objekte aus der Sammlung der Museen der Stadt
              Lüdenscheid und der Stadtgeschichte vorgestellt werden. Diese
              Objekte erzählen Geschichten, die immer Vergangenheit, Gegenwart
              und Zukunft verbinden.
            </p>
            <p>
              Das Projekt <b>Digital Diary</b> wird Objekte, Dokumente, Fotos
              und Filme unter Einschluss der mit ihnen in Verbindung stehenden
              Persönlichkeiten auf den Prüfstand stellen. Wie relevant sind die
              Objekte für ein breites Publikum, erregen sie Aufsehen, regen sie
              an zur Diskussion und kritischer Auseinandersetzung, haben sie
              sogar Streitpotenzial? Besitzen sie die Eigenschaften, starke
              Signale auszusenden, eine Botschaft zu vermitteln,
              Erkenntnisinteresse zu erzeugen? Haben die Objekte eine rationale
              und emotionale Seite, speichern sie Wissen und berühren sie
              zugleich die Sinne?
            </p>
            <p>
              Diese Fragen werden gestellt und sollen beantwortet werden – im
              digitalen und im ganz direkten Dialog mit der Stadt- und
              Regionalgesellschaft.
            </p>
            <p>
              Ermöglicht wurde dieses Projekt, das auch in den Sozialen Medien
              (Instagram:{' '}
              <Link to={RouteEnum.INSTAGRAM}>wunderkammer_museum</Link>) und in
              den Lüdenscheider Nachrichten veröffentlicht wird, durch eine
              Förderung der Kulturstiftung der Länder im Rahmen des Programmes{' '}
              <b>NeuStartKultur</b>.
            </p>
          </div>
          <Gallery
            className="p-32 mb-16"
            images={data.allDigitalDiary.edges.map((item) => ({
              id: item.node.id,
              image: item.node.frontmatter.image,
              title: item.node.frontmatter.title,
              slug: item.node.frontmatter.slug,
              subtitle: new Date(
                item.node.frontmatter.published_date,
              ).toLocaleDateString('de-DE', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              }),
            }))}
          />
        </div>
        <h1 style={{ writingMode: 'vertical-rl' }}>Digital Diary</h1>
      </div>
    </Layout>
  );
};

export const Head: React.FC = () => {
  return (
    <Meta
      description="Ein Museumstagebuch im World Wide Web:
      Heute beginnt eine Serie, in der in regelmäßiger Folge – jeweils
      am Samstag - Objekte aus der Sammlung der Museen der Stadt
      Lüdenscheid und der Stadtgeschichte vorgestellt werden. Diese
      Objekte erzählen Geschichten, die immer Vergangenheit, Gegenwart
      und Zukunft verbinden."
      title="Digital Diary"
    />
  );
};

export default Page;
